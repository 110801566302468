/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
// import ButtonAnalog from "./Depricated/Buttons/ButtonAnalog";
import React from "react";
import { theme } from "./theme";

const styles = {
  content: css`
    display: flex;
    flex-direction: column;
    padding: 10px;
    position: relative;
    font-size: 16px;
    width: 100%;
    gap: 20px;
    margin: 15px;
    span {
      font-family: "Arial", sans-serif;
    }

    strong {
      color: ${theme.colors.backZ};
    }
  `,
  headerText: css`
    font-size: 20px;
  `,
  header: css`
    display: flex;
    height: 40px;
    width: 100%;
  `,
  buttonContainer: css`
    background: linear-gradient(to right, ${theme.colors.backZ} 4px, transparent 4px) 0 0,
      linear-gradient(to right, ${theme.colors.backZ} 4px, transparent 4px) 0 100%,
      linear-gradient(to left, ${theme.colors.backZ} 4px, transparent 4px) 100% 0,
      linear-gradient(to left, ${theme.colors.backZ} 4px, transparent 4px) 100% 100%,
      linear-gradient(to bottom, ${theme.colors.backZ} 4px, transparent 4px) 0 0,
      linear-gradient(to bottom, ${theme.colors.backZ} 4px, transparent 4px) 100% 0,
      linear-gradient(to top, ${theme.colors.backZ} 4px, transparent 4px) 0 100%,
      linear-gradient(to top, ${theme.colors.backZ} 4px, transparent 4px) 100% 100%;
    background-repeat: no-repeat;
    background-size: 20px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 30px;
    margin-top: 20px;
    color: ${theme.colors.frontZ};
    cursor: pointer;
    transition: ${theme.transitions.smoothFast};
    align-self: center;
    outline: none;
    border: none;
    :hover {
      background: linear-gradient(to right, ${theme.colors.backZ} 4px, transparent 4px) 0 0,
        linear-gradient(to right, ${theme.colors.backZ} 4px, transparent 4px) 0 100%,
        linear-gradient(to left, ${theme.colors.backZ} 4px, transparent 4px) 100% 0,
        linear-gradient(to left, ${theme.colors.backZ} 4px, transparent 4px) 100% 100%,
        linear-gradient(to bottom, ${theme.colors.backZ} 4px, transparent 4px) 0 0,
        linear-gradient(to bottom, ${theme.colors.backZ} 4px, transparent 4px) 100% 0,
        linear-gradient(to top, ${theme.colors.backZ} 4px, transparent 4px) 0 100%,
        linear-gradient(to top, ${theme.colors.backZ} 4px, transparent 4px) 100% 100%;
    }
  `,
  link: css`
    text-decoration: underline;
    color: ${theme.colors.frontZ};
    cursor: pointer;
    background-color: transparent;
    outline: none;
    border: none;
    font-family: "Roboto Thin", sans-serif;
    font-size: 16px;
  `,
  blur: css`
    color: ${theme.colors.backZ};
  `,
  socialMediaIcon: css`
    height: 40px;
    cursor: pointer;
  `,
  iconList: css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
  `,
};

const About: React.FC = () => {
  return (
    <div css={styles.content}>
      <div css={styles.header}>
        <span css={styles.headerText}>Career</span>
      </div>
      <span>Software Developer / Mechanical Engineer / Brazilian Jiu Jitsu Influencer</span>
      <a
        href="/resume.pdf"
        download
        css={styles.buttonContainer}
      >
        Download Resume
      </a>
      <div css={styles.iconList}>
        <a href="https://www.linkedin.com/in/zacharyhulett">
          <img
            src="/LI-In-Bug.png"
            css={styles.socialMediaIcon}
            alt={"LinkedIn logo"}
          />
        </a>
        <a href="https://www.instagram.com/studentofbjj/">
          <img
            src="/instagram.svg"
            alt={"Instagram logo"}
            css={[
              styles.socialMediaIcon,
              css`
                padding-right: 4px;
              `,
            ]}
          />
        </a>
        <a href="https://github.com/zulufoxtrothotel">
          <img
            src="/github-mark-white.png"
            alt={"Github logo"}
            css={styles.socialMediaIcon}
          />
        </a>
      </div>
    </div>
  );
};
export default About;
