/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
// import ButtonAnalog from "./Depricated/Buttons/ButtonAnalog";
import React from "react";

import { useNavigate } from "react-router";
import { theme } from "./theme";
import AnalogButton from "./Depricated/Buttons/AnalogButton";

const styles = {
  root: css`
    display: flex;
    flex-direction: column;
    padding: 10px;
    position: relative;
    font-size: 16px;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    align-items: center;
    gap: 20px;
    strong {
      user-select: text;
    }
    span {
      user-select: text;
    }
    strong {
      color: ${theme.colors.frontZ};
    }
  `,
  containerText: css`
    font-size: 20px;
  `,
  scrollContainer: css`
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    width: 100%;
    gap: 20px;
  `,
  headerText: css`
    font-size: 20px;
    color: ${theme.colors.frontZ};
  `,
  headerSubText: css`
    font-size: 16px;
  `,
  regularText: css`
    font-size: 16px;
    padding-top: 5px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
  `,
  header: css`
    display: flex;
    height: 40px;
    width: 100%;
    flex-direction: column;
  `,
  buttonContainer: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
  `,
  link: css`
    text-decoration: underline;
    color: ${theme.colors.frontZ};
    cursor: pointer;
  `,
  blur: css`
    color: ${theme.colors.backZ};
  `,
  componentBox: css`
    display: flex;
    width: 300px;
    height: 350px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  `,
  row: css`
    display: flex;
    flex-direction: row;
    gap: 10px;
  `,
  wideRow: css`
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 100%;
    justify-content: space-between;
  `,
  subtitle: css`
    color: #fff;
    font-size: 14px;
  `,
  appContainer: css`
    display: flex;
    flex-direction: column;
    border: solid 1px ${theme.colors.backZ};
    background-color: ${theme.colors.backZ}1f;
    padding: 10px;
    border-radius: 8px;
    width: 100%;

    align-items: flex-start;
    justify-content: flex-start;
    gap: 5px;
    @media (min-width: ${theme.breakpoints.sm}px) {
      padding: 20px;
      gap: 10px;
    }
  `,
  card: css`
    display: flex;
    flex-direction: column;
    gap: 10px;
  `,
  button: css`
    width: 325px;
    align-self: center;
  `,
  appImage: css`
    width: 200px;
    border-radius: 8px;
  `,
  zoomImage: css`
    width: 400px;
    border-radius: 8px;
    overflow: hidden;
    transition: all 0.3s ease;
    :hover {
      transform: scale(2);
      transform-origin: bottom left;
    }
  `,
  webappImage: css`
    width: 100%;
    border-radius: 8px;
    height: 100%;
    object-fit: cover;
    transition: all 0.3s ease;
  `,
  downloadButtonContainer: css`
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 100%;
    padding: 10px 0;
    justify-content: flex-end;
    align-items: flex-end;
  `,
};

const URL = "https://cks-web-prod.azurewebsites.net/?email=guest%40championskeepscore.com&password=guest";
const SampleApps: React.FC = () => {
  return (
    <div css={styles.root}>
      <div css={styles.scrollContainer}>
        <div css={styles.header}>
          <span css={styles.containerText}>
            Apps - <em css={styles.headerSubText}>My porfolio of apps</em>{" "}
          </span>
        </div>
        <div css={styles.appContainer}>
          <span css={styles.headerText}>Motiv-Ai-Tion</span>
          <span css={styles.regularText}>
            Motiv-Ai-Tion is a mobile app (Android and iOS) that uses generative AI to create unique, personalized experiences for
            users.
          </span>

          <br />
          <div css={{ display: "flex", flexDirection: "row", gap: "15px", flexWrap: "wrap" }}>
            <div css={styles.row}>
              <img
                css={styles.appImage}
                src={"/steampunk-screenshot.png"}
                alt="Steampunk Screenshot"
              />
              <img
                css={styles.appImage}
                src={"/goth-screenshot.png"}
                alt="Goth Screenshot"
              />
            </div>
            <div css={styles.row}>
              <img
                css={styles.appImage}
                src={"/hodor-screenshot.png"}
                alt="Hodor Screenshot"
              />
              <img
                css={styles.appImage}
                src={"/witcher-screenshot.png"}
                alt="Witcher Screenshot"
              />
            </div>
          </div>
          <br />
          <div css={styles.wideRow}>
            <ul css={styles.regularText}>
              <strong>Key Features:</strong>
              <li>• Ever-expanding library of AI-driven motivational personalities</li>
              <li>• Tailor-made topics to keep you inspired</li>
              <li>• 3-day weather forecast via the National Weather Service API</li>
              <li>• Exclusive daily alerts for pro users</li>
              <li>• Smart backend moderation to ensure a positive experience</li>
            </ul>
            <div css={styles.downloadButtonContainer}>
              <a
                href="https://apps.apple.com/us/app/motiv-ai-tion/id6740501081"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={"/appleLight.png"}
                  alt="Download Apple App"
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.cks.motivaition"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={"/googleLight.png"}
                  alt="Download Android App"
                />
              </a>
            </div>
          </div>
        </div>
        <div css={styles.appContainer}>
          <span css={styles.headerText}>Champions Keep Score</span>
          <span css={styles.regularText}>
            Champions Keep Score is a web app that provides real time analytics and training resources for Brazilian Jiu Jitsu
            athletes.
          </span>

          <div css={styles.zoomImage}>
            <img
              src={"/cks-screenshot.png"}
              alt="Download Apple App"
              css={styles.webappImage}
            />
          </div>

          <br />
          <ul css={styles.regularText}>
            <strong>Key Features:</strong>
            <li>• Journal with custom Rich Text Editor</li>
            <li>• Sparring Analytics (beta testing)</li>
            <ul>
              <li>Upload video and tag key moments with intelligent tagging system</li>
              <li>Backend service creates valuable analytics</li>
              <li>Beautiful dashboard to visualize complex data</li>
              <li>Links to relevant journal entries and resources</li>
            </ul>
            <div css={styles.zoomImage}>
              <img
                src={"/analyzer-screenshot.png"}
                alt="Download Apple App"
                css={styles.webappImage}
              />
            </div>
            <li>• YouTube note taker</li>
            <ul>
              <li>Custom video player</li>
              <li>Progress bar with 'magnetic' nodes for scrolling to key moments</li>
              <li>Hashtags create custom scroll points for the notepad</li>
              <li>Timestamped notes that jump to key moments in videos</li>
            </ul>
            <div css={styles.zoomImage}>
              <img
                src={"/noteTaker-screenshot.png"}
                alt="Download Apple App"
                css={styles.webappImage}
              />
            </div>
            <li>• An intelligent meta tagging system that allows for user customization</li>
            <li>• OAuth 2.0 integration</li>
          </ul>
          <AnalogButton
            css={styles.button}
            color={theme.colors.frontZ}
            onClick={() => window.open(URL, "_blank")}
          >
            Go to Champions Keep Score! (Guest credentials will autofill)
          </AnalogButton>
        </div>
        {/* <div css={styles.card}>
        <h1>My current project: Champions Keep Score</h1>
        <div>
          <span>The button below will take you to my current personal project, Champions Keep Score.</span>
          <br />
          <span>
            CKS is a Brazilian Jiu-Jitsu journal and tracking app that is currently in beta testing. Feel free to poke around!
          </span>
          <br />
          <span>Some key features I'm most proud of are:</span>
          <ul>
            <li>Rich text editor</li>
            <li>YouTube note taker full of helpful features</li>
            <ul>
              <li>Custom video player</li>
              <li>Progress bar with 'snappy' nodes for scrolling to key moments</li>
              <li>Hashtags create custom scroll points for the notepad</li>
              <li>Timestamped notes that jump to key moments in videos</li>
            </ul>
            <li>An intelligent tagging system that allows for user customization</li>
            <li>OAuth 2.0 integration</li>
            <li>Customizable journals</li>
          </ul>
          <span>Use the following credentials when you log in: </span>
          <br />
          <span>
            email: <strong>guest@championskeepscore.com</strong>
          </span>
          <br />
          <span>
            password: <strong>guest</strong>
          </span>
        </div>
        <AnalogButton
          css={styles.button}
          color={theme.colors.frontZ}
          onClick={() => window.open(URL, "_blank")}
        >
          Go to Champions Keep Score!
        </AnalogButton>
      </div> */}
      </div>
    </div>
  );
};
export default SampleApps;
