/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import { theme } from "./theme";
import RotationShapes from "./Depricated/Transformations/RotationShapes";
import IntroCard from "./Depricated/Transformations/IntroCard";
import LightningStrike from "./Depricated/LightningStrike";
import AnalogButton from "./Depricated/Buttons/AnalogButton";
import MagneticBox from "./Depricated/MagneticBox";
import Carousel, { CarouselItem } from "./Utils/Carousel";

const styles = {
  root: css`
    display: flex;
    flex-direction: column;
    padding: 10px;
    position: relative;
    font-size: 16px;

    width: 100%;
    height: 100%;
    overflow-y: auto;
    align-items: center;
  `,
  headerText: css`
    font-size: 20px;
  `,
  headerSubText: css`
    font-size: 16px;
  `,
  header: css`
    display: flex;
    height: 40px;
    width: 100%;
    flex-direction: column;
  `,
  buttonContainer: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
  `,
  link: css`
    text-decoration: underline;
    color: ${theme.colors.frontZ};
    cursor: pointer;
  `,
  blur: css`
    color: ${theme.colors.backZ};
  `,
  componentBox: css`
    display: flex;
    width: 300px;
    height: 350px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  `,
  subtitle: css`
    font-size: 14px;
  `,
  content: css`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: center;
  `,
};

const carouselItems: CarouselItem[] = [
  {
    text: "Welcome Card (move slider back and forth)",
    element: <IntroCard />,
    components: ["introCard", "slider"],
  },
  {
    text: "Rotating Planes (pure css)",
    element: <RotationShapes />,
    components: ["rotationShapes", "verticalSlider", "slider"],
  },
  {
    text: "Animated Analog Button with Shocking Surprise!",
    element: (
      <LightningStrike>
        <AnalogButton color={theme.colors.frontZ}>Press me!</AnalogButton>
      </LightningStrike>
    ),
    components: ["analogButton", "lightningStrike"],
  },
  {
    text: "Field of magnetic dots attracted to mouse cursor",
    element: <MagneticBox />,
    components: ["magneticBox"],
  },
];

const CodeSamples: React.FC = () => {
  return (
    <div css={styles.root}>
      <div css={styles.header}>
        <span css={styles.headerText}>
          Example Code - <em css={styles.headerSubText}>An assortment of fun React components</em>{" "}
        </span>
      </div>
      <div css={styles.content}>
        <Carousel items={carouselItems} />
      </div>
    </div>
  );
};
export default CodeSamples;
